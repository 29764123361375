<template>
  <div>
    <div class="container__img__aboutUs">
      <img
        class="image-about-us-banner"
        src="https://i.ytimg.com/vi/XV2g-SSa-2c/maxresdefault.jpg"
        alt="banner about us"
      />

      <div class="container__img__aboutUs__info">
        <h1 class="text-title-aboutus">Sobre nosotros</h1>
      </div>
    </div>
    <div class="container__img__aboutUs__containers">
      <div class="container-fluid size d-flex flex-column align-items-center">
        <p>
          Somos una plataforma innovadora de educación sobre el maquillaje.
          Nuestra misión es brindar oportunidades de aprendizaje sobre nuevas
          tendencias, productos y técnicas de vanguardia.
          <br />
          <br />
          Con un equipo diverso de instructores calificados provenientes de
          distintos paises, aseguramos una experiencia de aprendizaje rica e
          inclusiva para nuestros usuarios.
        </p>
      </div>
    </div>
    <div class="container__img__aboutUs__containers">
      <div class="container-fluid size d-flex mobile-aboutus">
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 container__img__aboutUs__containers__box2"
        >
          <img
            src="img/theme/sobre.png"
            alt="imagen presentacion 1"
          />
        </div>
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 container__img__aboutUs__containers__box2"
        >
          <h1 class="text-uppercase">
            Aprende a maquillarte de forma sencilla y rápida
          </h1>

          <p>
            Ya sea que esté utilizando una computadora, tableta o teléfono
            inteligente, se puede acceder a nuestra plataforma desde cualquier
            dispositivo, lo que hace que el aprendizaje sea conveniente y
            agradable. Nuestra plataforma es de acceso gratuito, pero para
            aquellos que buscan mejorar su conocimiento del maquillaje,
            ofrecemos cursos premium que profundizan en el arte de la aplicación
            del maquillaje. Estos cursos brindan explicaciones detalladas y
            técnicas avanzadas para ayudarlo a dominar el arte del maquillaje.
          </p>
        </div>
      </div>
    </div>
    <div class="container__img__aboutUs__containers__box4 bg-rose-opacity">
      <div class="container-fluid size d-flex mobile-aboutus">
        <div
          class="col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center py-4"
        >
          <img
            src="img/theme/fundador.jpg"
            alt="imagen presentacion 1"
            style="max-height: 480px"
          />
        </div>
        <div
          class="col-md-6 col-lg-6 col-xl-6 container__img__aboutUs__containers__box2 pl-5 pb-0"
        >
          <h1 class="styles__title text-start ml-0">NUESTRO FUNDADOR</h1>

          <p class="styles__p mb-4">
            Miki Vicioso es el fundador de Maquíllate.com. Comenzó el proyecto
            con una visión de ofrecer a la mujer hispana de cualquier parte del
            mundo, una manera fácil, sencilla y aseguible de aprender sobre
            maquíllaje y cómo automaquillarse. En su tiempo libre, le encanta
            compartir con su familia y dar consejos de negocios a otros
            emprendedores.
          </p>
          <button class="btn btn-instagram-about-us bg-button-white w-100">
            <a
              class="w-100 d-flex align-items-center"
              href="https://www.instagram.com/mikivicioso/"
              style="text-decoration: none"
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
              <span class="mb-1">Seguir en Instagram</span>
            </a>
          </button>
          <button class="btn btn-tiktok-2 bg-button-white">
            <a
              class="w-100 d-flex align-items-center"
              href=" https://www.tiktok.com/@mikivicioso"
              style="text-decoration: none"
              target="_blank"
            >
              <img src="img/theme/tik_tok.svg" class="fa-tiktok" />
              <span class="mb-1">Seguir en TikTok</span>
            </a>
          </button>
          <button class="btn btn-linkedin-2 bg-button-white">
            <a
              class="w-100 d-flex align-items-center"
              href="https://www.linkedin.com/in/miki-vicioso-cocco-512b5137/"
              style="text-decoration: none"
              target="_blank"
            >
              <img src="img/theme/LINKEDIN.svg" class="fa-linkedin" />
              <span class="mb-1">Seguir en Linkedin</span>
            </a>
          </button>
        </div>
      </div>
    </div>
    <div
      class="container__img__aboutUs__containers__box5"
      style="display: none"
    >
      <div class="container-fluid size d-flex mobile-aboutus">
        <div
          class="col-md-5 col-lg-5 col-xl-5 container__img__aboutUs__containers__box2 pl-5"
        >
          <h1 class="styles__title text-uppercase text-center">
            Trabaja con nosotros
          </h1>

          <p class="styles__p mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <button class="btn btn-twitter-2">
            Unete a nuestro equipo en san francisco
          </button>
          <button class="btn btn-twitter-2">
            Unete a nuestro equipo en san francisco
          </button>
          <button class="btn btn-twitter-2">
            Unete a nuestro equipo en ankara
          </button>
          <button class="btn btn-twitter-2">
            Unete a nuestro equipo en florida
          </button>
        </div>
        <div
          class="col-md-7 col-lg-7 col-xl-7 container__img__aboutUs__containers__box2 pl-4"
        >
          <img
            src="http://stylelovely.com/esmiestilo/files/2021/01/tips-de-maquillaje-para-eventos-especiales.jpg"
            alt="imagen presentacion 1"
          />
        </div>
      </div>
    </div>
    <div class="container__img__aboutUs__containers__box6">
      <div class="container-fluid size d-flex mobile-aboutus">
        <div class="col-md-6 col-lg-6 col-xl-6">
          <img
            src="../../../../../public/img/theme/kit-medios-image.png"
            alt="imagen presentacion 1"
          />
        </div>
        <div
          class="col-md-6 col-lg-6 col-xl-6 container__img__aboutUs__containers__box2 pl-5"
        >
          <h1 class="styles__title text-uppercase text-left">Kit de medios.</h1>

          <p class="styles__p mb-4">
            ¡Gracias por tu interés en Maquíllate.com! Aquí encontrará nuestro
            kit de medios completo: logotipos descargables; iconos y fotos de
            nuestro CEO, Miki Vicioso.
          </p>

          <button class="btn btn-media">
            <a
              href="https://maquillate.com/kit-de-medios.zip"
              class="no-underline"
            >
              Descargar Kit de Medios
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AboutUs',
};
</script>

<style scoped>
.bg-button-white {
  background-color: #fff;
  width: 100%;
}
.bg-rose-opacity {
  background-color: #f7f2f4 !important;
}
.btn:hover {
  text-decoration: none !important;
}
a:not(.md-button):hover {
  text-decoration: none !important;
  color: white !important;
}

.container__img__aboutUs__containers__box6
  .container__img__aboutUs__containers__box2
  button.btn-media {
  text-decoration: none;
}
.md-theme-default a:not(.md-button) {
  color: #fff;
}

.container__img__aboutUs__info {
  height: 200px !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
  padding-top: 60px;
}
.image-about-us-banner {
  height: 200px;
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.text-title-aboutus {
  font-size: 2.5rem !important;
  text-transform: uppercase;
}

@media screen and (max-width: 576px) {
  .image-about-us-banner {
    height: 150px;
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    padding-top: 60px;
  }
  .container__img__aboutUs__info {
    height: 150px !important;
    background-color: rgba(0, 0, 0, 0.9) !important;
    padding-top: 60px;
  }
  .text-title-aboutus {
    font-size: 1.5rem !important;
    text-transform: uppercase;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .image-about-us-banner {
    height: 150px;
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    padding-top: 60px;
  }
  .container__img__aboutUs__info {
    height: 150px !important;
    background-color: rgba(0, 0, 0, 0.9) !important;
    padding-top: 50px;
  }

  .text-title-aboutus {
    font-size: 2rem !important;
    text-transform: uppercase;
  }
}

.btn-instagram-about-us {
  color: black !important;
  background-color: #fff;
  border: 1px solid #b40270;
}

.btn-instagram-about-us span {
  color: #b40270;
}
.btn-instagram-about-us:hover {
  background-color: #b40270;
  color: white !important;
}

.btn-instagram-about-us:hover span {
  color: white;
}

.container__img__aboutUs__containers__box4
  .container__img__aboutUs__containers__box2
  button {
  max-width: 100% !important;
}
</style>
